import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAddAddressActive: false,
  isCartActive: false,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setIsAddAddressActive(state, { payload }) {
      state.isCartActive = false;
      state.isAddAddressActive = payload;
    },
    setIsCartActive(state, { payload }) {
      state.isAddAddressActive = false;
      state.isCartActive = payload;
    },
  },
});

export const locationActions = locationSlice.actions;

export default locationSlice;
