import React, { Suspense, useEffect, useState, useRef } from "react";
import ImageLoader from "../chef/ImageLoader";
import "../login/login.css";
import AppContainer from "../util/AppContainer";
import { SuspenseImg } from "../util/SuspendImg";
import mainImage from "../../assets/loginTheme.png";
import MenuTopBar from "../menu/MenuTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  checkout,
  confirmOrder,
  sendOTP,
  setAddressForPreOrder,
} from "../../store/loginActions";
import bottomBarSlice, { bottomBarActions } from "../../store/bottomBarSlice";
import ReactGA from "react-ga4";
// Import React Scrit Libraray to load Google object
import Script from "react-load-script";
import { ORDER_DISTANCE_RANGE } from "../../store/constants";
import mixpanel from "mixpanel-browser";
import emailjs from "@emailjs/browser";
import { Checkbox } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { loginActions } from "../../store/loginSlice";

const CITY_NAME = "mumbai";
const TEMP_EMAIL = ["ashwin@cuirato.com", "admin@cuirato.com", "satish@cuirato.com"];

const Checkout = (props) => {
  const form = useRef();
  const [query, setQuery] = useState("");
  console.log(query);
  const [city, setCity] = useState("");
  const [location, setLocation] = useState(null);
  const userLocation = useSelector((state) => state.auth.location);
  const coords = useSelector((state) => state.auth.coords);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);
  const selectedAddress = useSelector((state) => state.auth.selectedAddress);
  const { cartData, isLoading } = useSelector(({ cart }) => cart);
  const [loginState, setLoginState] = useState({
    email: "",
    area: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorMessageForDelivery, setErrorMessageForDelivery] = useState("");
  const sourceLocation = {
    latitude: 19.116606,
    longitude: 72.8854982,
  };

  // Store autocomplete object in a ref.
  // This is done because refs do not trigger a re-render when changed.
  const autocompleteRef = useRef(null);
  console.log(autocompleteRef);

  const handleScriptLoad = () => {
    const options = {
      fields: ["formatted_address", "geometry", "name", "address_component"],
      componentRestrictions: { country: "In" },
      strictBounds: false,
      //types: ["geocode"],
    };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("searchAutoComplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocompleteRef.current.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    autocompleteRef.current.addListener("place_changed", function () {
      const addressObject = autocompleteRef.current.getPlace();
      console.log(addressObject);
      console.log(autocompleteRef.current.getPlace());
      let components = addressObject.address_components,
        // console.log(addressObject.name)
        // let components = addressObject.,

        city = "n/a";
      if (components) {
        for (let c = 0; c < components.length; ++c) {
          console.log(components[c].types.join("|"));
          if (
            components[c].types.indexOf("locality") > -1 &&
            components[c].types.indexOf("political") > -1
          ) {
            city = components[c].long_name;
            break;
          }
        }
      }
      if (addressObject && addressObject.geometry) {
        const locationObject = {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
          area: addressObject.formatted_address,
        };
        setLocation(locationObject);
      }
      setCity(city);
      setQuery(
        addressObject
          ? `${addressObject.name},${addressObject.formatted_address}`
          : ""
      );
      console.log(addressObject);
    });
  };

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/checkout" });
    ReactGA.event({
      category: "tab",
      action: "checkout",
    });
  }, []);

  const sendEmail = (e) => {
    const mobileNumber = "91" + user.phonenumber;
    dispatch(confirmOrder({ mobileNumber }));
    console.log("Order sucessfull sms sent");
    emailjs
      .sendForm(
        "service_3t471gh", // replace with your service id
        "template_1d10sj4", // replace with your template id
        form.current,
        "kWiFDOBeK7Oc_Y7Jg" // replace with your public key
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    console.log("Order sucessfull email sent");
  };

  const checkoutCart = (bookForLater) => {
    mixpanel.track("checked-out");
    if (loginState.email) {
      setErrorMessageForDelivery("");
      setError(false);
      // const distanceValue = distance(
      //   sourceLocation.latitude,
      //   sourceLocation.longitude,
      //   location.lat,
      //   location.lng,
      //   "K"
      // );
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(loginState.email)
      ) {
        setError(true);
        setErrorText("Email id is not valid");
        return;
      }
      // if (city.toLowerCase() !== CITY_NAME) {
      //   setErrorMessageForDelivery("Available only in Mumbai");
      //   return;
      // }
      // if (!bookForLater && distanceValue > ORDER_DISTANCE_RANGE) {
      //   setErrorMessageForDelivery(
      //     "Delivery area should be inside 12km from the kitchen in Andheri west. You can choose to book later and get delivery when service is available in your area."
      //   );
      //   return;
      // }

      let newAddress = {
        area: "",
        houseNumber: "",
        landMark: "",
        location: {
          lat: coords.lat,
          lng: coords.lng,
          locationName: userLocation,
        },
        tag: "default",
      };
      dispatch(setAddressForPreOrder(user.userid, newAddress));
      dispatch(loginActions.setSelectedAddress(newAddress));

      dispatch(
        checkout(
          cartData,
          user,
          loginState.email,
          newAddress,
        )
      );
      sendEmail();
    } else {
      if (!loginState.email) {
        setError(true);
        setErrorText("Email id is required");
        return;
      } else if (!loginState.area) {
        setError(true);
        setErrorText("Address is required");
        return;
      }
    }
  };

  const sendEmailOnConfirm = () => {
    let dishBookedName = "";
    cartData.forEach((data) => {
      dishBookedName += data.name + ", ";
    });

    const { username, phonenumber } = user;

    const mobileNumber = "91" + phonenumber;
    let templateParams = {
      user_name: username,
      phone_number: mobileNumber,
      dish_name: dishBookedName,
      area: selectedAddress.area,
      houseNumber: selectedAddress.houseNumber,
      landmark: selectedAddress.landMark,
      locationName: selectedAddress.location.locationName,
      lat: selectedAddress.location.lat,
      lng: selectedAddress.location.lng,
      tag: selectedAddress.tag,
      to_email: TEMP_EMAIL,
    };
    dispatch(confirmOrder({ mobileNumber }));
    emailjs
      .send(
        "service_3t471gh", // replace with your service id
        "template_3x8ijk9", // replace with your template id
        templateParams,
        "kWiFDOBeK7Oc_Y7Jg" // replace with your public key
      )
      .then((res) => console.log(res.text))
      .catch((error) => console.log(error));
  };

  const onConfirmHandler = () => {
    dispatch(
      checkout(
        cartData,
        user,
        "",
        selectedAddress,
      )
    );
    sendEmailOnConfirm();
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  // This function calculate the distance between two end point
  const distance = (
    sourceLat,
    sourceLon,
    destinationLat,
    destinationLon,
    unit
  ) => {
    if (sourceLat == destinationLat && sourceLon == destinationLon) {
      return 0;
    } else {
      var radlat1 = (Math.PI * sourceLat) / 180;
      var radlat2 = (Math.PI * destinationLat) / 180;
      var theta = sourceLon - destinationLon;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  const onBackClick = () => {
    switchTab("cart");
  };

  const [isChecked, setIsChecked] = useState(true);

  return (
    <AppContainer id={props.id}>
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw&libraries=places"
        onLoad={handleScriptLoad}
      />
      <div className="cart-top-bar">
        <MenuTopBar
          name="Checkout"
          cuisine={isAreaServiceable ? "Deliver now" : "Pre-order"}
          notMenu={true}
          onBackClick={onBackClick}
        />
      </div>
      <form
        className="login-container"
        ref={form}
        onSubmit={(e) => e.preventDefault()}
      >
        <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
          <div className="mt">
            <SuspenseImg src={mainImage}></SuspenseImg>{" "}
          </div>
        </Suspense>
        

        {isAreaServiceable ? (
          <>
            <div className="logo-container">
              <h5>Checkout</h5>
              <p>
                Online payment will be added soon.
                Currently, you can pay through UPI apps while receiving delivery.
              </p>
            </div>
            <div className="payon_delivery-container">
              <h6> PAY ON DELIVERY</h6>
              {isChecked ? <CheckBox /> : <CheckBoxOutlineBlank />}
            </div>
          </>
        ) : (
          <>
            <div className="logo-container">
              <h5>Checkout</h5>
              <p>
                We will update you when your preferred chefs go live. You will be
                able schedule your delivery through the app.
              </p>
            </div>
            <div className={error ? "" : "hide"}>
              <p className="error-msg">{errorText}</p>
            </div>
            <label htmlFor="email">
              Email
              <input
                id="email"
                type="email"
                className="login-phone input-text"
                name="email"
                onChange={({ target }) => {
                  setLoginState({
                    ...loginState,
                    [target.name]: target.value,
                  });
                  setError("");
                  setErrorText("");
                }}
                placeholder="Enter your email"
                value={loginState.email}
              />
            </label>

            {/* <label htmlFor="searchAutoComplete">
              Address
              <input
                id="searchAutoComplete"
                type="text"
                className="login-phone"
                name="area"
                onChange={({ target }) => {
                  setQuery("");
                  setLoginState({
                    ...loginState,
                    [target.name]: target.value,
                  });
                  setErrorMessageForDelivery("");
                }}
                placeholder="Enter your address"
                value={query ? query : loginState.area}
              />
            </label> */}
          </>
        )}

        {errorMessageForDelivery && (
          <p className="checkout-error-msg"> {errorMessageForDelivery} </p>
        )}
        <div className="checkout-option active" id="cart_option">
          {isAreaServiceable ? (
            <button
              type="submit"
              disabled={isChecked ? false : true}
              className={isChecked ? "bottom-button" : "bottom-button disabled"}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              CONFIRM
            </button>
          ) : (
            <button
              type="submit"
              disabled={
                loginState.email === ""
                  ? true
                  : false
              }
              onClick={() => checkoutCart(errorMessageForDelivery && true)}
              className={`bottom-button ${
                loginState.email === ""
                  ? `disabled`
                  : ""
              }`}
            >
              {errorMessageForDelivery ? "Book for later" : "Book"}
            </button>
          )}
        </div>
      </form>
    </AppContainer>
  );
};

export default Checkout;
