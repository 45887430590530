import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import "./dish-menu-category-card.css";
import { useDispatch } from "react-redux";
import { dishActions } from "../../store/dishSlice";

const DishMenuCategoryCard = ({ index, name, totalDishes, img }) => {
  console.log(index);
  const dispatch = useDispatch();
  const tileMore = () => {
    dispatch(dishActions.updateTilesClicked());
    dispatch(dishActions.updateActiveTile(name));
  };

  return (
    <div className="card-container">
      {/* dish info with gradient overlay in bg */}
      <div className="text-container" onClick={tileMore}>
        <h1>{name}</h1>
        <p>{totalDishes} chefs</p>
      </div>

      {/* dish image */}
      <img
        src={img ? S3_MENU_THUMBNAIL_FOLDER + img : ""}
        alt="card image"
        className="dish-image"
        onClick={tileMore}
      />
    </div>
  );
};

export default DishMenuCategoryCard;
