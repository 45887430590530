import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { loginActions } from "../../store/loginSlice";
import "./location-search.css";
import Script from "react-load-script";
import { locationActions } from "../../store/locationSlice";
import { ORDER_DISTANCE_RANGE } from "../../store/constants";
import { cartAction } from "../../store/cartSlice";

const LocationSearch = () => {
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState(null);
  const dispatch = useDispatch();
  const { isAddAddressActive } = useSelector((state) => state.location);
  const isServiceable = useSelector((state) => state.auth.isServiceable);
  const user_location = useSelector(({ auth }) => auth.location);

  const sourceLocation = {
    latitude: 19.116606,
    longitude: 72.8854982,
  };

  // This function calculate the distance between two end point
  const distance = (
    sourceLat,
    sourceLon,
    destinationLat,
    destinationLon,
    unit
  ) => {
    if (sourceLat == destinationLat && sourceLon == destinationLon) {
      return 0;
    } else {
      var radlat1 = (Math.PI * sourceLat) / 180;
      var radlat2 = (Math.PI * destinationLat) / 180;
      var theta = sourceLon - destinationLon;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  useEffect(() => {
    if (location !== null) {
      const distanceValue = distance(
        sourceLocation.latitude,
        sourceLocation.longitude,
        location.lat,
        location.lng,
        "K"
      );

      if (distanceValue > ORDER_DISTANCE_RANGE) {
        dispatch(loginActions.setIsServiceable(false));
        dispatch(loginActions.setTotalDistance(0));
        dispatch(loginActions.setSelectedAddress(null));
      } else {
        dispatch(loginActions.setIsServiceable(true));
        dispatch(loginActions.setTotalDistance(distanceValue));
        dispatch(loginActions.setSelectedAddress(null));
        dispatch(cartAction.setIsAddressOptionsVisible(true))
      }
      dispatch(
        loginActions.setCoords({
          lat: location.lat,
          lng: location.lng,
        })
      );

      dispatch(loginActions.setLocation(location.area));
    }
  }, [location]);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const [loginState, setLoginState] = useState({
    area: "",
  });

  const autocompleteRef = useRef(null);
  const handleScriptLoad = () => {
    const options = {
      fields: ["formatted_address", "geometry", "name", "address_component"],
      componentRestrictions: { country: "In" },
      strictBounds: false,
      //types: ["geocode"],
    };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("searchAutoComplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocompleteRef.current.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    autocompleteRef.current.addListener("place_changed", function () {
      const addressObject = autocompleteRef.current.getPlace();
      if (addressObject && addressObject.geometry) {
        const locationObject = {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
          area: addressObject.formatted_address,
        };
        setLocation(locationObject);
      }

      setQuery(addressObject ? addressObject.formatted_address : "");
      dispatch(loginActions.setIsLocationEnabled());
      isAddAddressActive ? switchTab("addressForm") : switchTab("chef");
      dispatch(locationActions.setIsAddAddressActive(false));
    });
  };

  return (
    <>
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw&libraries=places"
        onLoad={handleScriptLoad}
      />

      <div className="search_wrapper">
        {user_location ? (
          <div
            className="location-search_back-btn"
            onClick={() => {
              isAddAddressActive ? switchTab("addressForm") : switchTab("chef");
              dispatch(locationActions.setIsAddAddressActive(false));
            }}
          >
            <img src="images/icon-back.svg" alt="back" loading="lazy" />
          </div>
        ) : null}
        <div className="location-search_input">
          <label htmlFor="searchAutoComplete">
            <input
              style={{
                width: "100%",
                boxSizing: " border-box",
                borderRadius: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#E8814D",
                fontSize: "18px",
                border: "none",
                background: "transparent",
                padding: "20px 10px",
              }}
              id="searchAutoComplete"
              type="text"
              name="area"
              onChange={({ target }) => {
                setQuery("");
                setLoginState({
                  ...loginState,
                  [target.name]: target.value,
                });
              }}
              placeholder="Enter area, street name..."
              value={query ? query : loginState.area}
            />
          </label>
        </div>

        <div className="location-search_img">
          <img src="images/search_item.svg" alt="search-item" loading="lazy" />
        </div>
      </div>
    </>
  );
};

export default LocationSearch;
