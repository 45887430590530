import React from "react";
import { useSelector } from "react-redux";
import "./chef-cards.css";
const PREPARING_TIME = 15;

const ChefName = (props) => {
  const totalDistance = useSelector((state) => state.auth.totalDistance);

  const totalDeliveryTime = () => {
    if (totalDistance <= 1) {
      return 10 + PREPARING_TIME;
    } else if (totalDistance <= 2) {
      return 20 + PREPARING_TIME;
    } else if (totalDistance <= 3) {
      return 30 + PREPARING_TIME;
    } else {
      return 40 + PREPARING_TIME;
    }
  };

  return (
    <div className="chef-detail">
      <h4 className="chef-name">Chef {props.name}</h4>
      <a className="btn-view-profile" role="button">
        {totalDistance == 0
          ? "Happy to serve you"
          : `${totalDeliveryTime()} Min`}
        {/* <span>&#062;&#062;</span> */}
      </a>
    </div>
  );
};

export default ChefName;
