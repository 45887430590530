import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";

const LocationPin = ({ text }) => (
  <div className="pin">
    <AddLocationOutlinedIcon />
    <p className="pin-text">{text}</p>
  </div>
);

export default LocationPin;
