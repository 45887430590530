import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chef: false,
  dishes: false,
  dishMenuCategoryCardDetails: false,
  cart: false,
  account: false,
  profile: false,
  faq: false,
  about: false,
  storie: false,
  underconstruction: false,
  dishclick: false,
  checkout: false,
  isOrderSucessfull: false,
  benefits: false,
  dishBook: false,
  isEditUserDetail: false,
  bottombardisplay: true,
  locationSelector: false,
  location: false,
  address: false,
  addressForm: false,
  tracking: false,
  orderSummary: false,
};

const bottomBarSlice = createSlice({
  name: "bottomBar",
  initialState,
  reducers: {
    loadTab(state, action) {
      switch (action.payload.tabName) {
        case "chef":
          state.chef = true;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.cart = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.account = false;
          state.storie = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "dishes":
          state.dishes = true;
          state.dishMenuCategoryCardDetails = false;
          state.chef = false;
          state.cart = false;
          state.storie = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.account = false;
          state.dishclick = true;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "dishMenuCategoryCardDetails":
          state.dishes = false;
          state.dishMenuCategoryCardDetails = true;
          state.chef = false;
          state.cart = false;
          state.storie = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.account = false;
          state.dishclick = true;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "cart":
          state.cart = true;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "checkout":
          state.checkout = true;
          state.isOrderSucessfull = false;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.benefits = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "orderSucessfull":
          state.checkout = false;
          state.isOrderSucessfull = true;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.benefits = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "account":
          state.account = true;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.cart = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "benefits":
          state.account = false;
          state.benefits = true;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.cart = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "profile":
          state.profile = true;
          state.faq = false;
          state.about = false;
          state.chef = false;
          state.storie = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.cart = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "faq":
          state.storie = false;
          state.account = false;
          state.profile = false;
          state.faq = true;
          state.about = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.cart = false;
          state.dishclick = false;
          state.chef = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "about":
          state.storie = false;
          state.account = false;
          state.profile = false;
          state.faq = false;
          state.about = true;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.cart = false;
          state.dishclick = false;
          state.chef = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "storie":
          state.storie = true;
          state.account = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.dishBook = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.cart = false;
          state.dishclick = false;
          state.chef = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "dishBook":
          state.dishBook = true;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.isEditUserDetail = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;
        case "editUser":
          state.isEditUserDetail = true;
          state.dishBook = false;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.bottombardisplay = true;
          state.locationSelector = false;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;

        case "locationSelector":
          state.locationSelector = true;
          state.isEditUserDetail = false;
          state.dishBook = false;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.bottombardisplay = true;
          state.location = false;
          state.address = false;
          state.addressForm = false;
          state.tracking = false;
          state.orderSummary = false;

          break;

        case "location":
          state.location = true;
          state.isEditUserDetail = false;
          state.dishBook = false;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.bottombardisplay = false;
          state.address = false;
          state.addressForm = false;
          state.locationSelector = false;
          state.tracking = false;
          state.orderSummary = false;

          break;

        case "address":
          state.address = true;
          state.location = false;
          state.isEditUserDetail = false;
          state.dishBook = false;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.bottombardisplay = false;
          state.addressForm = false;
          state.locationSelector = false;
          state.tracking = false;
          state.orderSummary = false;

          break;

        case "addressForm":
          state.addressForm = true;
          state.location = false;
          state.isEditUserDetail = false;
          state.dishBook = false;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.bottombardisplay = false;
          state.address = false;
          state.locationSelector = false;
          state.tracking = false;
          state.orderSummary = false;

          break;

        case "tracking":
          state.tracking = true;
          state.addressForm = false;
          state.location = false;
          state.isEditUserDetail = false;
          state.dishBook = false;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.bottombardisplay = false;
          state.address = false;
          state.locationSelector = false;
          state.orderSummary = false;

        case "orderSummary":
          state.orderSummary = true;
          state.tracking = false;
          state.addressForm = false;
          state.location = false;
          state.isEditUserDetail = false;
          state.dishBook = false;
          state.cart = false;
          state.chef = false;
          state.dishes = false;
          state.dishMenuCategoryCardDetails = false;
          state.profile = false;
          state.faq = false;
          state.about = false;
          state.storie = false;
          state.account = false;
          state.dishclick = false;
          state.checkout = false;
          state.isOrderSucessfull = false;
          state.benefits = false;
          state.bottombardisplay = true;
          state.address = false;
          state.locationSelector = false;

          break;

        default:
          break;
      }
    },
    changeBottombar(state, action) {
      state.bottombardisplay = action.payload.bottombardisplay;
      return state;
    },
    hideCart(state, action) {
      state.cart = false;
      return state;
    },
    showCart(state, action) {
      state.cart = true;
      return state;
    },
  },
});

export const bottomBarActions = bottomBarSlice.actions;

export default bottomBarSlice;
