import { useState, useEffect } from "react";

import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  google,
} from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { useSelector } from "react-redux";

const Map = () => {
  // google.maps.fullscreenControl=false;

  const { lat, lng } = useSelector(({ auth }) => auth.coords);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        options={{
          disableDefaultUI: true,
        }}
        defaultZoom={15}
        defaultCenter={{ lat: lat, lng: lng }}
      >
        <Marker
          // onDragEnd={(e) => {
          //   console.log(e.latLng.lat()); }}
          // draggable={true}
          position={{ lat: lat, lng: lng }}
        />
      </GoogleMap>
    ))
  );

  return (
    <MapWithAMarker
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={
        <div
          style={{
            height: `100%`,
            height: "200px",
            backgroundColor: "#e3e3e3",
          }}
        />
      }
      containerElement={<div style={{ height: `200px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default Map;
