import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import ChefCardInfoSlider from "./ChefCardInfoSlider";
import ChefCardProfile from "./ChefCardProfile";
import ChefCardSlider from "./ChefCardSlider";
import ChefMenuCategorySlider from "./ChefMenuCategorySlider";
import MenuButton from "./MenuButton";

const ChefCard = (props) => {
  const dispatch = useDispatch();
  const labels = {
    experienceDesc: "Total experience",
    qualificationLabel: "Educational Qualification",
    experienceImage: "images/experiance.svg",
  };
  let chefProfileDetails = props.details;

  const experienceDetails = chefProfileDetails.pastExperiences.map((exp) => {
    let year = exp.years > 1 ? " years" : " year"
    return {
      img: exp.brandImg,
      header: exp.brandName,
      desc: exp.city + ", " + Math.ceil(exp.years) + year,
    };
  });

  const { isMenuActive } = chefProfileDetails;


  return (
    <ChefCardSlider>
      <div className="profile-organization" onTouchStart={()=> dispatch(chefActions.setRunDemo())}>
        <ChefCardProfile item={chefProfileDetails} labels={labels} />
        <ChefCardInfoSlider
          header="Professional Experience"
          items={experienceDetails}
          scrollerBind={props.scrollerBind}
        />
      </div>
      <div className="menu-action">
        {isMenuActive && (
          <ChefMenuCategorySlider
            header="Menu Highlights"
            id={chefProfileDetails._id}
            scrollerBind={props.scrollerBind}
          />
        )}
        <MenuButton
          img="images/icon-menu.svg"
          alt="menu"
          title={isMenuActive ? "Explore Menu" : "Coming soon"}
          chefId={chefProfileDetails._id}
          isMenuActive={isMenuActive}
        />
      </div>
    </ChefCardSlider>
  );
};

export default ChefCard;
