import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Script from "react-load-script";
import { bottomBarActions } from "../../store/bottomBarSlice";
import AppContainer from "../util/AppContainer";
import "./locationSelector.css";
import { loginActions } from "../../store/loginSlice";
import { useEffect } from "react";
import GPSLocator from "./GPSLocator";
import RecentSearches from "./RecentSearches";
import LocationSearch from "./LocationSearch";

const LocationSelector = () => {
  const isAddAddressActive = useSelector(
    (state) => state.location.isAddAddressActive
  );

  return (
    <AppContainer>
      <LocationSearch />
      <GPSLocator />
      {!isAddAddressActive && <RecentSearches />}
    </AppContainer>
  );
};

export default LocationSelector;
