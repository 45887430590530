import Banners from "./banner/Banners";
import ChefCards from "./chef/ChefCards";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import AppContainer from "./util/AppContainer";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from "react-redux";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { loginActions } from "../store/loginSlice";

// TODO: replace the step object in global level 
const steps = [
  // {
  //   content: <h5>Let's begin our journey!</h5>,
  //   locale: { skip: <strong aria-label="skip">Skip</strong> },
  //   placement: "center",
  //   target: "body",
  // },
  {
    title : 'Swipe to browse chefs',
    content: 'Stop scrolling endlessly, these chefs deserve your attention!',
    spotlightPadding: 0,
    target: ".profile-right",
    disableBeacon: true,
  },
  {
    title: 'Banners',
    content: 'Read important information and announcements. Get the best deals!',
    floaterProps: {
      disableAnimation: true,
    },
    disableBeacon : true,
    spotlightPadding: 0,
    target: ".slick-list",
  },
  {
    title: 'Chef profile',
    content: 'Professionally trained and experienced to get your food right every single time.',
    spotlightPadding: 25,
    target: ".slick-active .profile-organization",
    disableBeacon : true,
    disableScrolling : false,
  },
  {
    title : 'Chef’s Menu',
    content: 'View menu highlights or explore the detailed menu.',
    spotlightPadding: 25,
    target: ".slick-active .menu-action",
    locale: { 
      last: 'Done',
      next : ''
    },
    showProgress : 'false',
    disableBeacon : true,
    disableScrolling : false,
  },
];

const Home = (props) => {
  const dispatch = useDispatch();
  const [run, setRun] = useState(false);
  let tourGuid = useSelector((state) => state.auth.tourState);
  const user = useSelector(({ auth }) => auth.user);
  const [autoSwipe, setAutoSwipe] = useState(false);
  const runDemo = useSelector((state) => state.chef.runDemo);

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/home" });
    ReactGA.event({
      category: "tab",
      action: "home",
    });

    mixpanel.track('home');
  }, []);

  const isServiceable = useSelector((state) => state.auth.isServiceable);
  console.log("isServiceable :",isServiceable)


  useEffect(() => {
    if (runDemo) {
      // setTimeout(() => {
        if (!tourGuid) {
          setRun(true);
        }
      // }, 5000);
    }
  }, [runDemo]);

  const handleJoyrideCallback = (data) => {
    const { status, type, action } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if(action == 'close'){
      setRun(false);
      return;
    }
    if (finishedStatuses.includes(status)) {
      dispatch(loginActions.setTourState());
      setRun(false);
      mixpanel.track('demo-done');
    }
    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
    if([EVENTS.STEP_AFTER].includes(type)){
      if(data.index == 0){
        setAutoSwipe(0);
      }
    }
    // if([EVENTS.STEP_BEFORE].includes(type)){
    //   if(data.index == 0){
    //     setTimeout(() => {
    //       setAutoSwipe(0);
    //     }, 500);
    //     mixpanel.track('demo-1');
    //   } else if(data.index == 1){
    //     mixpanel.track('demo-2');
    //   } else if(data.index == 2){
    //     const content = document.querySelector(".organisation-slider");
    //     const contentwidth = content.offsetWidth;
    //     mixpanel.track('demo-3');
    //   } else if(data.index == 3){
    //     const content = document.querySelector(".cuisine-slider");
    //     const contentwidth = content.offsetWidth;
    //     mixpanel.track('demo-4');
    //   }
    // }
  };

  return (
    <AppContainer id={props.id}>
      <TopBar />
      <Banners />
      <ChefCards autoSwipe={autoSwipe} />
      <BottomBar />
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={false}
        steps={steps}
        disableOverlay={false}
        hideBackButton = {true}
        disableScrolling = {true}
        styles={{
          options: {
            arrowColor:"#F8EDD5",
            primaryColor: "#e8814d",
            zIndex: 10000,
            backgroundColor: '#F8EDD5',
            overlayColor: 'rgba(0, 0, 0, 0)',
          },
          tooltipTitle: {
            fontSize: 16,
            margin: '0 0 0px 0',
            textAlign : 'left',
            paddingLeft : '10px',
            color : '#670000'
          },
          tooltipContent: {
            fontSize: 13,
            padding: '10px 10px',
            textAlign : 'left',
            fontWeight : 300,
            color : '#670000'
          },
          buttonNext : {
            fontSize : '13px'
          }
        }}
      />
    </AppContainer>
  );
};

export default Home;
