import MenuTopBar from "../menu/MenuTopBar";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import DoneIcon from "@material-ui/icons/Done";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import AppContainer from "../util/AppContainer";
import "./order-summary.css";
import { useDispatch, useSelector } from "react-redux";
import Dish from "../menu/Dish";
import { setBookingData } from "../../store/loginActions";
import { useEffect } from "react";
import "../dishBook/dishBook.css";
import "../menu/browse-menu-button.css";
import BottomBar from "../BottomBar";

const OrderSummary = () => {
  const deliveryAddress = useSelector((state) => state.auth.selectedAddress);
  console.log("DELIVERY_ADDRESS : ", deliveryAddress);
  const { phonenumber } = useSelector((state) => state.auth.user);
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  const orderDetails =useSelector(state=>state.auth.orderDetails)



  return (
    <AppContainer>
      <div className="orderSummary_wrapper">
        <div className="orderSummary_top">
          {/* <MenuTopBar
          name="Order Summary"
          notMenu={true}
          // onBackClick={onBackClick}
        /> */}
          <div className="orderSummary_icon">
            <ReceiptIcon />
          </div>
          <div className="orderSummary_content">
            <h5>ORDER SUMMARY</h5>
          </div>
        </div>
        <div className="order-info">
          <div className="order-info_left">
            <span>Delivery Time</span>
            <div className="delivery-info">
              <div className="order-info_icon">
                <AccessTimeOutlinedIcon />
              </div>
              <div className="order-info_time">
                <span className="order-info_span">45 Mins</span>
              </div>
            </div>
          </div>
          <div className="order-info_right">
            <span>Phone Number</span>
            <div className="delivery-info">
              <div className="order-info_icon">
                <LocalPhoneIcon />
              </div>
              <div className="order-info_time">
                <span className="order-info_span">8655861799</span>
              </div>
            </div>
          </div>
        </div>
        <div className="status_badge">
          <h6>Preparing your order</h6>
          <DoneIcon />
        </div>
        {/* <div className="ordered-dishes_container">
        <div className="ordered_dish">
          <div className="dish_name"></div>
        </div>
    </div> */}
        <h6 className="order-details">ORDER DETAILS</h6>
        <div className="ordered-dishes_container">
          <section className="section-food-accordian">
            {orderDetails && orderDetails.bookedDishes && (
              <div className="food-accordian">
                <div className="dish-wrapper-list">
                  {orderDetails.bookedDishes.map((bookedDish, i) => {
                    let dish = bookedDish.dish;
                    return (
                      <>
                        {dish.price && (
                          <Dish
                            key={i}
                            id={dish._id}
                            name={dish.name}
                            category={"Starters"}
                            price={bookedDish.price}
                            type={dish.type ? dish.type.toUpperCase() : "VEG"}
                            img={dish.img ? S3_MENU_THUMBNAIL_FOLDER + dish.img : ""}
                            description={dish.description}
                            fromCart={false}
                            dish={dish}
                            calculateTotal={true}
                            isFromCart={false}
                            dealPrice={bookedDish.dealPrice}
                            parentComponent="dishBook"
                            isBooked={true}
                            isScheduleActive={false}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </section>
        </div>
        <div className="delivery-address">
          <div className="delivery-address_top">
            <span className="delivery-address_span">DELIVERY ADDRESS</span>
            <LocationOnIcon />
          </div>
          <p className="delivery-address_content">
            {deliveryAddress?.location?.locationName}
          </p>
        </div>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default OrderSummary;
