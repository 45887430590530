
import OneSignal from 'react-onesignal';

export default async function runOneSigna() {
  await OneSignal.init({ appId: "d055d6fe-8144-4987-b7ce-cffc0b090618", allowLocalhostAsSecureOrigin: true});
  OneSignal.showSlidedownPrompt();
}


// const getdat=()=>{

//     window.OneSignal = window.OneSignal || [];
//     OneSignal.push(function() {
//       OneSignal.init({
//         appId: "d055d6fe-8144-4987-b7ce-cffc0b090618",
//       });
//     });
// }