import "./recent-searches.css";
import HistoryIcon from "@material-ui/icons/History";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "../../store/loginSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { ORDER_DISTANCE_RANGE } from "../../store/constants";
import { cartAction } from "../../store/cartSlice";

const RecentSearches = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const selectedAddress = useSelector((state) => state.auth.selectedAddress);
  const addresses = user.addressLine1;
  console.log("SELECTED_ADDRESS", selectedAddress);

  const sourceLocation = {
    latitude: 19.116606,
    longitude: 72.8854982,
  };

  // This function calculate the distance between two end point
  const distance = (
    sourceLat,
    sourceLon,
    destinationLat,
    destinationLon,
    unit
  ) => {
    if (sourceLat == destinationLat && sourceLon == destinationLon) {
      return 0;
    } else {
      var radlat1 = (Math.PI * sourceLat) / 180;
      var radlat2 = (Math.PI * destinationLat) / 180;
      var theta = sourceLon - destinationLon;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onSelectAddressHandler = (data) => {
    const distanceValue = distance(
      sourceLocation.latitude,
      sourceLocation.longitude,
      data.location.lat,
      data.location.lng,
      "K"
    );

    if (distanceValue > ORDER_DISTANCE_RANGE) {
      dispatch(loginActions.setIsServiceable(false));
      dispatch(loginActions.setTotalDistance(0));
    } else {
      dispatch(loginActions.setIsServiceable(true));
      dispatch(loginActions.setTotalDistance(distanceValue));
    }

    let coords = { lat: data.location.lat, lng: data.location.lng };
    dispatch(loginActions.setSelectedAddress(data));
    dispatch(loginActions.setLocation(data.location.locationName));
    dispatch(cartAction.setIsAddressOptionsVisible(false));
    dispatch(loginActions.setCoords(coords));
    switchTab("chef");
  };

  return (
    <>
      {addresses.length ? (
        <div className="recent-searches_wrapper">
          SAVED ADDRESSES
          {addresses.length
            ? addresses.map((data) => {
                return (
                  <div
                    key={data._id}
                    className="recent-searches_container"
                    onClick={() => onSelectAddressHandler(data)}
                  >
                    <div className="recent-searches_icon">
                      <HistoryIcon style={{ fontSize: "2.3rem" }} />
                    </div>
                    <div className="recent-address_container">
                      <h6>{data.tag}</h6>
                      <p>
                        {data.location.locationName.length >= 70
                          ? `${data.location.locationName.slice(0, 70)}...`
                          : data.location.locationName}
                      </p>
                      <hr className="recent_hr" />
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      ) : null}
    </>
  );
};

export default RecentSearches;
