import "./GPS-Locator.css";
import MyLocation from "@material-ui/icons/MyLocation";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "../../store/loginSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { locationActions } from "../../store/locationSlice";
import { ORDER_DISTANCE_RANGE } from "../../store/constants";

const GPSLocator = () => {
  const API_ENDPOINT = "https://maps.googleapis.com/maps/api/geocode/json";
  const API_KEY = "AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw";
  const dispatch = useDispatch();
  const { isAddAddressActive } = useSelector((state) => state.location);

  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });

  const sourceLocation = {
    latitude: 19.116606,
    longitude: 72.8854982,
  };

  const showError = (error) => {
    toast.error(error, {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "info1",
    });
  };

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
    dispatch(
      loginActions.setCoords({
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      })
    );
  };

  const onError = (error) => {
    setLocation({
      loaded: false,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  const onGeoLocationClickHandler = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
      showError("Geolocation not supported");
    }
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  // This function calculate the distance between two end point
  const distance = (
    sourceLat,
    sourceLon,
    destinationLat,
    destinationLon,
    unit
  ) => {
    if (sourceLat == destinationLat && sourceLon == destinationLon) {
      return 0;
    } else {
      var radlat1 = (Math.PI * sourceLat) / 180;
      var radlat2 = (Math.PI * destinationLat) / 180;
      var theta = sourceLon - destinationLon;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  const GPSHandler = async () => {
    if (location.loaded == true) {
      let res = await axios.get(
        `${API_ENDPOINT}?latlng=${location.coordinates.lat},${location.coordinates.lng}&key=${API_KEY}`
      );

      if (res.status == 200) {
        const { data } = res;

        const distanceValue = distance(
          sourceLocation.latitude,
          sourceLocation.longitude,
          location.coordinates.lat,
          location.coordinates.lng,
          "K"
        );

        if (distanceValue > ORDER_DISTANCE_RANGE) {
          dispatch(loginActions.setIsServiceable(false));
          dispatch(loginActions.setTotalDistance(0));
        } else {
          dispatch(loginActions.setIsServiceable(true));
          dispatch(loginActions.setTotalDistance(distanceValue));
        }
        dispatch(loginActions.setLocation(data.results[0].formatted_address));
        isAddAddressActive ? switchTab("addressForm") : switchTab("chef");
        dispatch(locationActions.setIsAddAddressActive(false));
      } else {
        showError("Unable to fetch current location");
      }
    } else {
      if (location?.error?.message) {
        showError(location.error.message);
      }
    }
  };

  useEffect(() => {
    GPSHandler();
  }, [location]);

  return (
    <>
      <div className="gps_wrapper" onClick={() => onGeoLocationClickHandler()}>
        <div className="gps_icon">
          <MyLocation />
        </div>
        <div className="gps_content">
          <h6>Use Current Location</h6>
          <p>Using GPS</p>
        </div>
      </div>
      <hr className="gps_hr" />
      <ToastContainer limit={1} />
    </>
  );
};

export default GPSLocator;
