import { useEffect } from "react";
import BottomBar from "../BottomBar";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { useSelector, useDispatch } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import "../menu/browse-menu-button.css";
import ReactGA from "react-ga4";
import { setBookingData } from "../../store/loginActions";
import "./about.css";

const About = () => {
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "about",
    });
  }, []);
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBookingData(user.userid));
  }, [dispatch, user.userid]);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab('profile');
  };

  return (
    <AppContainer>
      <div className="cart-wrapper">
        <div className="cart-top-bar">
          <MenuTopBar
            name="About"
            cuisine="Know who we're"
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        <section className="about">
            <div>
              <h5>What problem are we solving?</h5>
              
              <div>
                <h6>Quality assurance is expensive</h6>
                <p>If you want to have quality-assured, authentic food usually available at fine-dine or starred restaurants, you need to spend a lot of money. These charges are due to heavy markups from restaurants and food delivery apps or aggregators.</p>
                <p>We’re connecting you directly to the makers of the authentic cuisines so that you can order food directly from these professional chefs. This ensures that you get quality food without draining your wallets.</p>
              </div>
              
              <div className="my">
                <h6>Professional chefs are exploited in India - Chef Sanjeev Kapoor</h6>
                <p>Professional chefs who cook your food at high end restaurants earn 20k-30k per month while working long hours. We’re set to change that by providing them a platform to build a personal brand and bringing them closer to the community.</p>
                <p>Chefs can earn 3-4 times more than what they were earning with starred restaurants, be entrepreneurs and be recognised for their work.</p>
              </div>
            </div>
            
            <div className="my">
              <h5>Vision</h5>
              <h6>Unite people through food.</h6>
              <p>Food has the quality to bring people together. We want to bring the creators of food closer to the community so people are able to build relationships with chefs and other like-minded food lovers.</p>
            </div>
          
            <div> 
              <h5>Mission</h5>
              <h6>A personal chef for every meal.</h6>
              <p>We want to empower the chef community by making them accessible and affordable so that people can explore and enjoy quality assured, worry-free food.</p>
            </div>
        </section>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default About;
