import { useState } from "react";
import { Collapse } from "react-collapse";

const FAQItem = ({faq}) => {
   let [isOpenFAQ, setIsOpenFAQ] = useState(false);
  
    const toggleFAQ = () => {
      setIsOpenFAQ(!isOpenFAQ);
  };
  return (
    <Collapse isOpened={true} key={faq.id}>
      {isOpenFAQ ? (
        <li className="collapsible-item active">
          <div
            className="collapsible-header decrease-horizontal-padding"
            onClick={() => toggleFAQ(faq.id)}
          >
            <div className="title-dish">
              <h5>{faq.question}</h5>
            </div>
            <div className="icon-down-div">
              <img
                src="images/icon-collapse.svg"
                alt="icon-collapse"
                loading="lazy"
              />
            </div>
          </div>
          {faq.answers.map((answer, i)=><p className="collapsible-body" key={i}>
            {answer}
          </p>)}
        </li>
      ) : (
        <li className="collapsible-item" onClick={() => toggleFAQ(faq.id)}>
          <div className="collapsible-header decrease-horizontal-padding">
            <div className="title-dish">
              <h5>{faq.question}</h5>
            </div>
            <div className="icon-down-div">
              <img
                src="images/icon-collapse.svg"
                alt="icon-collapse"
                loading="lazy"
              />
            </div>
          </div>
        </li>
      )}
    </Collapse>)
}

export default FAQItem;