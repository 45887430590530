import { useEffect, useState } from "react";
import BottomBar from "../BottomBar";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { useDispatch } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ReactGA from "react-ga4";
import "./faq.css";
import FAQItem from "./FAQItem";

const faqs = [      
  {
    question: "What does Cuirato do?",
    answers: [
      "Cuirato is an app on which you can order authentic, gourmet cuisine by choosing one of the many professional chefs as your personal chefs!",]
  },
  {
    question: "How does it work?",
    answers: [
      "Chefs would be cooking their cuisine in cloud kitchens. The app will show all the chefs currently available and in your delivery range (4-12km). You can choose your preferred chef/s, check their menu and order dishes. ",]
  },
  {
    question: "What’s different?",
    answers: [
      "You can connect with chefs. You tell them how you like your pasta or how much spice you can take. You can be sure of proper gourmet food and at a price that’s way lower than just delivery apps or fine dining restaurants.",]
  },
  {
    question: "What’s happening now?",
    answers: [
      "Our first cloud kitchen which will have chefs cooking your fave dishes is coming up soon. 4 cuisines - Indian, Tandoori, Chinese and Continental coming up. Pre-order your dishes and the first who do, get chef-prepared meals at prices that are too good to believe!",]
  },
  {
    question: "Where are you launching first?",
    answers: [
      "Mumbai.",]
  },
  {
    question: "Which cuisines are available?",
    answers: [
      "4 cuisines - Indian, Tandoori, Chinese and Continental coming up.",]
  },
  {
    question: "When will the pre-order food be delivered?",
    answers: [
      "1-2 months. We’re working on setting up cloud kitchens for all the chefs on the platform. Once they’re up and running you will be the first ones to taste their food.",]
  },
  {
    question: "Why pre-order now?",
    answers: [
      "We need people who love authentic food to have this experience before everybody else. This will help us to get feedback from you and improve. For your valuable feedback, we’re offering dishes at a deep discount.",]
  },
  {
    question: "Why are prices so low right now?",
    answers: [
      "We want our initial customers to test the food and give us feedback on the overall experience. So, we’re providing dishes at a deep discount in return for your valuable feedback.",]
  },
  {
    question: "How will you deliver? Who will deliver food?",
    answers: [
      "We have signed up with 3rd party delivery partners who are experts in delivering food who will take care of your delivery.",]
  },
  {
    question: "How much time does it take to deliver the order after placing it?",
    answers: [
      "Ideally, 30-45 mins. But it depends on multiple factors like weather, traffic, busy kitchen etc. Delivery timings will be shown to you on the app.",]
  },
  {
    question: "What are the delivery charges?",
    answers: [
      "Delivery charges depend on how far the location is to the kitchen. For all pre-orders, delivery is free.",]
  },
  {
    question: "Are chefs available daily?",
    answers: [
      "During the week, chefs will not be available on their off days usually on weekdays - Monday to Thursday.", "During the day, chefs will be available for 8-9 hours which will include at least  2 meal times - Breakfast, Lunch, Snacks, Dinner.",]
  },
  {
    question: "What is a cloud kitchen?",
    answers: [
      "A cloud kitchen is simply a kitchen setup where chefs can cook your fav dishes and deliver it to your homes. A cloud kitchen doesn’t have a dine-in facility.",]
  },
]

const FAQ = () => {
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "faq",
    });
  }, []);
  const dispatch = useDispatch();

  return (
    <AppContainer>
      <div className="cart-wrapper">
        <div className="cart-top-bar">
          <MenuTopBar
            name="FAQ"
            cuisine="What's your policy?"
            notMenu={true}
            onBackClick={()=>dispatch(bottomBarActions.loadTab({ tabName: "profile" }))}
          />
        </div>
        <section className="section-food-accordian">
          <div className="food-accordian">
            <ul className="collapsible">
          {
            faqs.map((faq) => 
              <FAQItem key={faq.id} faq={faq} />
          )
        }
        </ul>
        </div>
      </section>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default FAQ;
