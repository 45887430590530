import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import "./order-sucessfull.css";

const OrderSucessfull = () => {
  // below chunk of code is used to redirect automatically after 3000 to survey page
  // useEffect(() => {
  //   setTimeout(() => {
  //     window.location.href = "https://www.survey.cuirato.com/";
  //   }, 3000);
  // }, []);

  const redirect = () => {
    setTimeout(() => {
      dispatch(bottomBarActions.loadTab({ tabName: "orderSummary" }));
    }, 3000);
  };

  useEffect(() => {
    redirect();
  }, []);

  const dispatch = useDispatch();
  return (
    <div className="order-sucessfull-container">
      <img src="images/order-sucessfull.gif" alt="order sucessfull gif" />
      <h4>Order confirmed</h4>
      <p>
        Thanks for using Cuirato. Your request has been successfully placed.
      </p>
      {/* <button
        className="btn-fill"
        onClick={() =>
          dispatch(bottomBarActions.loadTab({ tabName: "tracking" }))
        }
      >
        Go back to home
      </button>
      <button
        className="btn-text"
        onClick={() =>
          dispatch(bottomBarActions.loadTab({ tabName: "dishBook" }))
        }
      >
        Order details
      </button> */}
    </div>
  );
};

export default OrderSucessfull;
