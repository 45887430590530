import { createSlice } from "@reduxjs/toolkit";

const initialState = { dishBand: {}, dishBandLoaded: false, currentPrice: 0, tilesClicked: false, activeTile: 0 };

const dishSlice = createSlice({
  name: "Dish",
  initialState,
  reducers: {
    replaceDishBand(state, action) {
      state.dishBand = action.payload.dishBand;
      state.dishBandLoaded = true;
    },
    updatePrice(state, action) {
      state.currentPrice = action.payload;
    },
    updateTilesClicked(state) {
      state.tilesClicked = !state.tilesClicked;
    },
    updateActiveTile(state, action) {
      state.activeTile = action.payload;
    }
  },
});

export const dishActions = dishSlice.actions;

export default dishSlice;
