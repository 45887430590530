import React, { useState } from "react";
import "./location-selection.css";
import mixpanel from "mixpanel-browser";
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
// import useGeoLocation from "../../hooks/useGeoLocation";

const LocationSelection = () => {
  // const city = useGeoLocation();
  const dispatch = useDispatch();
  

  const sendAnalytics = () => {
    mixpanel.track("location-clicked");
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const location = useSelector(({ auth }) => auth.location);

  return (
    <li
      onClick={() => switchTab("locationSelector")}
      className={
        location?.length >= 16 ? "select-location overflow" : "select-location"
      }
    >
      {/* <select className="form-select" aria-label="Default select example" onClick={sendAnalytics} >
        <option defaultValue>{
          city ? city : "Mumbai"
        }</option>
      </select> */}
      {location}
    </li>
    // <li className={styles["select-location"]}>
    //   <select
    //     className={styles["form-select"]}
    //     aria-label="Default select example"
    //   >
    //     <option defaultValue>Select Location</option>
    //     <option value="1">Home - Malad West, Mumbai</option>
    //     <option value="2">Two</option>
    //     <option value="3">Three</option>
    //   </select>
    // </li>
  );
};

export default LocationSelection;
