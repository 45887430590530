import React from "react";
import "./Tracking.css";
import Map from "./Map/Map";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import NewReleasesOutlined from "@material-ui/icons/NewReleasesOutlined";
import AppContainer from "../util/AppContainer";

const location = {
  address: "Motihari Mera ghar",
  lat: 26.65617,
  lng: 84.918182,
};

const Tracking = () => {
  return (
    <AppContainer>
      <div className="Main-container">
        <div className="top">
          <div className="top-map">
            <Map location={location} zoomLevel={17} />
          </div>
        </div>
        <div className="buttom">
          <div className="info-header">
            <div className="info-left">
              <AccessTimeOutlinedIcon />
              <div className="inner-info">
                <span className="span-inner-text">Delivery Time</span>
                <span className="span-inner-value">20 Min</span>
              </div>
            </div>
            <div className="info-right">
              <NewReleasesOutlined />
              <div className="inner-info">
                <span className="span-inner-text">Order Number</span>
                <span className="span-inner-value">CU4590908</span>
              </div>
            </div>
          </div>
          <div className="Center-wrapper">
            <div className="cent-left">
              <ul className="ul-tracker">
                <li className="ul-tracker-list">
                  <div className="logo-circle-fst">
                    <img
                      className="list-img"
                      src="./images/cuiratologo.png"
                      alt="tracker-logo"
                    />
                  </div>
                </li>
                <li className="ul-tracker-list">
                  <div className="logo-circle-fst">
                    <img
                      className="list-img"
                      src="./images/yes.png"
                      alt="tracker-logo"
                    />
                  </div>
                </li>
                <li className="ul-tracker-list">
                  <div className="logo-circle-fst">
                    <img
                      className="list-img"
                      src="./images/yes.png"
                      alt="tracker-logo"
                    />
                  </div>
                </li>
                <li className="ul-tracker-list">
                  <div className="logo-circle-snd">
                    <img
                      className="list-img"
                      src="./images/cross.png"
                      alt="tracker-logo"
                    />
                  </div>
                </li>
                <li className="ul-tracker-list">
                  <div className="logo-circle-snd">
                    <img
                      className="list-img"
                      src="./images/cross.png"
                      alt="tracker-logo"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className="cent-right">
              <div className="cent-text">
                <span className="cent-text-status">Cuiratos Kitchen</span>
                <span className="cent-text-status-snd">Malad West, Mumbai</span>
              </div>
              <div className="cent-text">
                <span className="cent-text-status">Order confirmed</span>
                <span className="cent-text-status-snd">
                  Your order has been Confirmed
                </span>
              </div>
              <div className="cent-text">
                <span className="cent-text-status">Order prepared</span>
                <span className="cent-text-status-snd">
                  Your order has been prepared
                </span>
              </div>
              <div className="cent-text">
                <span className="cent-text-status">Delivery in progress</span>
                <span className="cent-text-status-snd">
                  Hang on! Your food is on the way{" "}
                </span>
              </div>
              <div className="cent-text">
                <span className="cent-text-status">Delivery address</span>
                <span className="cent-text-status-snd">
                  Bandra West, Mumbai
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="buttom-container">
          <div className="buttom-info">
            <img src="./images/Rectangle-10.png" alt="Dilevery Person" />
            <div className="info-details">
              <span className="span-text-name">divyank</span>
              <span className="span-text-role">Mumbai</span>
            </div>
          </div>
          <div className="right-icon">
            <div className="right-icon-circle">
              <img src="./images/phone.png" alt="calling icons" />
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default Tracking;
