// set sms template
export const MSG91_SMS_SENDER_ID = "CUIRAT";

export const MSG91_SMS_TEMPLATE_ID = "61eff73341b08647fb7b47f8";

export const MSG91_SMS_CHECKOUT_TEMPLATE_ID = "6303990c1f0d3d71af4bc8c3";

// sms auth key
export const MSG91_AUTH_KEY = "371349A3i17DHDg61cefebfP1";

export const GOOGLE_ANALYTICS_KEY_PROD = "G-ND9RB7HYBY"

export const GOOGLE_ANALYTICS_KEY_DEV = "G-ND9RB7HYBZ"

// mixpanel auth key
export const MIXPANEL_AUTH_KEY_PROD = "7c8e374528b94b1fda0c2c7d4fc53eb1";

export const MIXPANEL_AUTH_KEY_DEV = "dfdd924c590644d7948f6d9802041121";

export const API_TEST = "https://api-test.cuirato.com/api/";

// export const API_TEST = "https://dev-api.cuirato.com/api/";

// export const API_TEST = "http://127.0.0.1:8000/api/";

export const MSG91_URL = "https://api.msg91.com/api/v5/";

export const S3_MENU_FOLDER = "https://cuirato-images.s3.ap-south-1.amazonaws.com/menu/";

export const S3_MENU_THUMBNAIL_FOLDER = "https://cuirato-images.s3.ap-south-1.amazonaws.com/menu/thumbnail/";

export const ORDER_DISTANCE_RANGE = 3;