import React from "react";
import "./menu-categories.css";
import "../base.css";
import MenuNotFound from "./MenuNotFound";
import DishMenuCategoryCard from "./DishMenuCategoryCard";
import { useSelector } from "react-redux";


const DishMenuCategories = (props) => {
  const user = useSelector(({ auth }) => auth.user);

  console.log("dishmenucategories", props.categories);
  
  return (
    <section className="section-food-accordian">
      <div className="food-accordian">
        <ul className="collapsible">
          {/* <li className='popular-cousine'>Popular cuisine</li> */}
          {
          // props.noDishes ? (
          //   <MenuNotFound
          //     img="images/Empty State-05.svg"
          //     header="Unlock Chef Menus."
          //     description="Browse Chefs and unlock their menu. Get quick access to all their dishes here."
          //   />
          // ) : 
          props.categories?.length > 0 ? (
            <div className='category-card-container'>
              {props.categories.map((category, index) => (
                <DishMenuCategoryCard
                  key={index}
                  index={index}
                  name={category.name}
                  totalDishes={category.categories.length}
                  img={category.img}
                />
              ))}
            </div>
          ) : (
            <MenuNotFound
              img="images/Empty State-06.svg"
              header="No dishes match your search."
              description="Please check menus of other chefs."
            />
          )}
        </ul>
      </div>
    </section>
  );
};
export default DishMenuCategories;