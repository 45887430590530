import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { cartAction } from "../../store/cartSlice";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import "./address.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useState } from "react";
import { ORDER_DISTANCE_RANGE } from "../../store/constants";
import { loginActions } from "../../store/loginSlice";

const Address = () => {
  const sourceLocation = {
    latitude: 19.116606,
    longitude: 72.8854982,
  };

  // This function calculate the distance between two end point
  const distance = (
    sourceLat,
    sourceLon,
    destinationLat,
    destinationLon,
    unit
  ) => {
    if (sourceLat == destinationLat && sourceLon == destinationLon) {
      return 0;
    } else {
      var radlat1 = (Math.PI * sourceLat) / 180;
      var radlat2 = (Math.PI * destinationLat) / 180;
      var theta = sourceLon - destinationLon;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  const dispatch = useDispatch();
  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("cart");
  };

  const [selectedAddress, setSelectedAddress] = useState({});

  const user = useSelector((state) => state.auth.user);
  const addresses = user.addressLine1;

  const onSelectAddressHandler = (data) => {
    const distanceValue = distance(
      sourceLocation.latitude,
      sourceLocation.longitude,
      data.location.lat,
      data.location.lng,
      "K"
    );

    if (distanceValue > ORDER_DISTANCE_RANGE) {
      dispatch(loginActions.setIsServiceable(false));
      dispatch(loginActions.setTotalDistance(0));
    } else {
      dispatch(loginActions.setIsServiceable(true));
      dispatch(loginActions.setTotalDistance(distanceValue));
    }
    dispatch(loginActions.setSelectedAddress(data));
    dispatch(cartAction.setIsAddressOptionsVisible(false));
    switchTab("cart");
  };

  return (
    <AppContainer>
      <div className="address-wrapper">
        <div className="address-top-bar">
          <MenuTopBar
            name="Select Delivery Address"
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        <div className="address-card_wrapper">
          {addresses.length
            ? addresses.map((data) => {
                return (
                  <>
                    <div
                      key={data._id}
                      className="address_card"
                      onClick={() => {
                        onSelectAddressHandler(data);
                        setSelectedAddress(data);
                      }}
                    >
                      <div className="tag_container">
                        <h6>{data.tag}</h6>
                        <LocationOnIcon
                          className={
                            selectedAddress?._id == data._id ? "selected" : ""
                          }
                        />
                      </div>
                      <p style={{ width: "90%" }}>
                        {data.location.locationName}
                      </p>
                    </div>
                  </>
                );
              })
            : "No Saved Addresses"}
        </div>

        <div className="select_btn">
          {!addresses.length && (
            // (
            //   <button
            //     onClick={() => {
            //       dispatch(cartAction.setIsAddressOptionsVisible(false));
            //       switchTab("cart");
            //     }}
            //     className="address-form_actionbtn"
            //   >
            //     CONTINUE
            //   </button>
            // ) :

            <button
              onClick={() => {
                dispatch(cartAction.setIsAddressOptionsVisible(false));
                switchTab("addressForm");
              }}
              className="address-form_actionbtn"
            >
              ADD ONE
            </button>
          )}
        </div>
      </div>
    </AppContainer>
  );
};

export default Address;
